import React from 'react';
import PropTypes from 'prop-types';
import TrackResultTable from './TrackResultTable';

const TrackResults = ({ trackingResults }) => {
  const { tarckingResultsBihPoste, trackingResultsPosteSrpske, trackingResultsXexpress } = trackingResults;

  return (
    <>
      {tarckingResultsBihPoste && tarckingResultsBihPoste.length && (
        <TrackResultTable
          serviceName={'bhPosta'}
          trackingResults={tarckingResultsBihPoste}
        />
      )}

      {trackingResultsPosteSrpske && trackingResultsPosteSrpske.length && (
        <TrackResultTable
          serviceName={'posteSrpske'}
          trackingResults={trackingResultsPosteSrpske}
        />
      )}
      {trackingResultsXexpress && trackingResultsXexpress.length && (
        <TrackResultTable
          serviceName={'xExspress'}
          trackingResults={trackingResultsXexpress}
        />
      )}
    </>
  );
};

TrackResults.propTypes = {
  trackingResults: PropTypes.object.isRequired,
};

export default TrackResults;
