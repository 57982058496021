import React from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const TrackResultRow = ({ trackingResult }) => {
  let isDelivered =
    trackingResult.message === 'POŠILJKA DOSTAVLJENA' ||
    trackingResult.message === 'POŠILJKA ISPORUČENA' ||
    trackingResult.message === 'Pošiljka je dostavljena';
  return (
    <Table.Row positive={isDelivered}>
      <Table.Cell>{trackingResult.message}</Table.Cell>
      <Table.Cell>{trackingResult.location || "" }</Table.Cell>
      <Table.Cell>{trackingResult.date}</Table.Cell>
    </Table.Row>
  );
};

TrackResultRow.propTypes = {
  trackingResult: PropTypes.object.isRequired,
};

export default TrackResultRow;
